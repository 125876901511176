import Cookies from "js-cookie";
import { calculateExpiry, isOneWeekDifference } from "utils/preschoolHelper";

const kutIsLoggedIn = Symbol("kutIsLoggedIn");

function setIsLoggedInCookie() {
  const domain =
    process.env.NODE_ENV === "development" ? "localhost" : "kutuki.in";

  Cookies.set(kutIsLoggedIn.description, true, { expires: 30, domain });
}

class SessionHelper {
  id = null;
  email = null;
  phone = null;
  name = null;
  role = "PARENT";

  childProfiles = [];

  userCourseMappings = [];
  courses = [];
  batches = [];
  products = [];
  productMap = {};
  selectedProfile = null;

  token = null;
  tokenExpiry = new Date().getTime();
  
  isDemoUser = false;
  accessType = [];
  isOnlyWorkSheetUser = false;

  paymentOptions = {}
  paymentReminder = {}
  
  permissions = {}
  refreshTokenExpiry = new Date().getTime();

  onSignIn({ childProfiles, user, refreshTokenExpiry,refreshToken, tokenExpiry, accessToken }) {
    this.id = user.id;
    this.name = user.name;
    this.phone = user.phone;
    this.email = user.emailId;

    this.refreshTokenExpiry = refreshTokenExpiry;
    this.childProfiles = childProfiles;
    this.tokenExpiry = tokenExpiry;
    this.token = accessToken;

    setIsLoggedInCookie();
    localStorage.setItem("kutRefreshTokenExpiry", refreshTokenExpiry);
    localStorage.setItem("kutRefreshToken", refreshToken);
  }

  setPaymentOptions() {
    let result = {};
    let resultV2 = {};

    const preschoolUserCourseMappings = this.userCourseMappings.filter(mapping =>
      Boolean(mapping.course.plans.find(planId => this.productMap[planId]?.type === 'preschool'))
    );

    for (const mapping of preschoolUserCourseMappings) {
      let reminderData = isOneWeekDifference(mapping);

      if (reminderData?.diplayPaymentOption) {
        resultV2[mapping?.batchId?.toString()] = [{
          batchId: mapping.batchId,
          courseId: mapping.courseId,
          courseName: mapping.courseName,
          expiryDate: mapping?.expiryDate
        }, {
          batchId: mapping.batchId,
          courseId: mapping.courseId,
          courseName: mapping.courseName,
          expiryDate: mapping?.expiryDate
        }];
      }

      if (calculateExpiry(mapping?.expiryDate)) {
        result[mapping?.batchId?.toString()] = [{
          batchId: mapping.batchId,
          courseId: mapping.courseId,
          courseName: mapping.course.name,
          expiryDate: mapping?.expiryDate
        }, {
          batchId: mapping.batchId,
          courseId: mapping.courseId,
          courseName: mapping.courseName,
          expiryDate: mapping?.expiryDate
        }];
      }
    }

    this.paymentOptions = { ...result };
    this.paymentReminder = { ...resultV2 };
  }

  onCreateSession({ child, courses, batches, products, accessType, permissions, userCourseMappings }) {
    this.userCourseMappings = userCourseMappings;
    this.courses = courses;
    this.batches = batches;
    this.products = products;
    this.productMap = {};
    for (let index = 0; index < products.length; index++) {
      this.productMap[products[index].id] = products[index];
    }

    this.selectedProfile = child;
    
    this.accessType = accessType;
  
    this.permissions = permissions;
  }

  onRestoreSession({
    token,
    tokenExpiry,
    childProfiles,
    child,
    user,
    courses,
    batches,
    products,
    accessType,
    permissions,
    userCourseMappings
  }) {
    this.id = user.id;
    this.name = user.name;
    this.phone = user.phone;
    this.email = user.email;

    this.childProfiles = childProfiles;

    this.selectedProfile = child;
    this.userCourseMappings = userCourseMappings;
    this.courses = courses;
    this.batches = batches;
    this.products = products;
    this.productMap = {};
    for (let index = 0; index < products.length; index++) {
      this.productMap[products[index].id] = products[index];
    }

    this.token = token;
    this.tokenExpiry = tokenExpiry;
    
    this.accessType = accessType;

    this.isOnlyWorkSheetUser = !accessType.includes("MINI_PROGRAM") && !accessType.includes("PRESCHOOL") && !accessType.includes("TRIAL_USER") && accessType.includes("DIGITAL_WORKSHEET_USER");

    this.isOnlyRecordingUser = !accessType.includes("MINI_PROGRAM") && !accessType.includes("PRESCHOOL") && !accessType.includes("TRIAL_USER") && accessType.includes("DIGITAL_RECORDING_USER");
    
    this.permissions = permissions;
  }

  onSignOut() {
    this.token = null;
    this.tokenExpiry = null;
    this.refreshTokenExpiry = null;

    this.id = null;
    this.name = null;
    this.phone = null;
    this.email = null;

    this.batches = [];
    this.courses = [];
    this.userCourseMappings = [];
    this.products = [];
    this.childProfiles = [];
    this.selectedProfile = null;
    
    this.accessType = [];
    this.isOnlyWorkSheetUser = false;
    this.permissions = {}

    Cookies.remove(kutIsLoggedIn.description);
    Cookies.remove("kut_token");
  
    localStorage.removeItem("kutProfile");
    localStorage.removeItem("kutRefreshToken");
    localStorage.removeItem("kutRefreshTokenExpiry");
    localStorage.removeItem("kutDisplayPaymentOption");
  }

  isLoggedIn() {
    return Cookies.get(kutIsLoggedIn.description);
  }

  getFullProfile() {
    return this.isLoggedIn()
      ? {
          id: this.id,
          name: this.name,
          phone: this.phone,
          email: this.email,
          courses: this.courses,
          childProfiles: this.childProfiles,
          selectedChildProfile: this.selectedProfile,
        }
      : {};
  }
  
  canAccessFeature(acceptedPermissions) {
    let canAccess = false;
    
    this.accessType.forEach(curr => {
      canAccess = canAccess || acceptedPermissions.indexOf(curr) > -1;
    });
    
    return canAccess;
  }
}

export default new SessionHelper();
