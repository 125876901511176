import * as firebase from "firebase/app";
import "firebase/auth";
import { Client } from "./_clientService";
import sessionHelper from "services/sessionHelper";
import rollbarService from "services/rollbarService";
import { logEvent } from "services/analyticsService";
import { clearFilter } from "utils/filterUtils";
import { getUser, gerUserStaticData } from "services/usersService";

async function signIntoFirebaseWithCustomToken(firebaseToken) {
  const childId = sessionHelper?.selectedProfile?.id || localStorage.getItem('kutProfile');
  try {
    if (!childId) throw new Error('No child profile found for generating firebase token.');

    if (!firebaseToken) {
      // Fetch token if not passed
      const result = await new Client({
        path: `/auth/firebaseToken?addChildId=true&childId=${childId}`,
      }).get();

      if (result.code !== 200) {
        throw new Error(result.message);
      }

      firebaseToken = result?.data?.firebaseToken;
    }

    // Login with firebase custom token
    await firebase.auth().signInWithCustomToken(firebaseToken);
  } catch (error) {
    console.error(error);
  }

}

async function signIn({phone, firebaseUid, firebaseToken, otp, isStaticOTP}) {
  const result = await new Client({
    path: `/signin`,
    payload: {
      phone: phone,
      mode: "phone",
      vendor: isStaticOTP?"msg91":"firebase",
      firebaseUid,
      firebaseToken,
      otp
    }
  }).post();
  if (result.code !== 200) {
    throw new Error(result.message);
  }

  // store the data in the session data module.

  sessionHelper.token = result?.data?.tokens.accessToken;
  const user = await getUser();

  sessionHelper.onSignIn({
    childProfiles: user.children,
    user,
    refreshTokenExpiry: result?.data?.tokens.refreshExpireAt,
    refreshToken: result?.data?.tokens.refreshToken,
    tokenExpiry: result?.data?.tokens.accessExpireAt,
    accessToken: result?.data?.tokens.accessToken
  });

  logEvent("LOGIN", {
    email: sessionHelper.email,
    phone: sessionHelper.phone,
    userId: sessionHelper.isLoggedIn() ? sessionHelper.id : "NA",
  });

  rollbarService.instance.configure({
    payload: {
      person: {
        id: sessionHelper.id,
        email: sessionHelper.email,
        phone: sessionHelper.phone,
      },
    },
  });

  return {user};
}

  async function signOut() {
  await firebase.auth().signOut();
  
  // clear the filters selected.
  clearFilter();

  // reset the data in session data module
  sessionHelper.onSignOut();
}

async function createSession(id) {
  const result = await gerUserStaticData(id);

  if (result?.error) {
    throw new Error(
      result?.message ||
        "Uh oh! Something went wrong setting up your Kutuki experience. Pleae try again"
    );
  }

  sessionHelper.onCreateSession(result);

  sessionHelper.setPaymentOptions(result.userCourseMappings);

  signIntoFirebaseWithCustomToken();

  return result;
}

async function restoreSession() {
  let kutProfile = localStorage.getItem("kutProfile");

  if (!kutProfile) {
    throw new Error(
      "Local profile not found, exiting the restore session flow"
    );
  }

  const { childId } = JSON.parse(kutProfile);

  const result = await new Client({
    path: "/auth/access",
    headers: {
      "kutuki-refresh-token": localStorage.getItem("kutRefreshToken"),
    }
  }).get();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  sessionHelper.token = result.data.accessToken;
  sessionHelper.tokenExpiry = result.data.accessExpireAt;

  const userData = await gerUserStaticData(childId);

  const sessionData = {
    token:result.data.accessToken,
    tokenExpiry:result.data.accessExpireAt,
    ...userData,
  }

  // set the session data for session restore
  sessionHelper.onRestoreSession(sessionData);

  sessionHelper.setPaymentOptions(userData.userCourseMappings);

  signIntoFirebaseWithCustomToken();

  rollbarService.instance.configure({
    payload: {
      person: {
        id: userData?.user?.id,
        email: userData?.user?.email,
        phone: userData?.user?.phone,
      },
    },
  });

  return result;
}

async function setTemporaryToken(firebaseUserId, phone) {
  const result = await new Client({
    path: "/auth/parent/temp",
    payload: {
      userId: firebaseUserId,
      phone,
    },
  }).post();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  // set the token and tokenExpiry in session
  sessionHelper.token = result.token;
  sessionHelper.tokenExpiry = result.tokenExpiry;
  sessionHelper.firebaseId = firebaseUserId;
  sessionHelper.phone = phone;

  return result;
}

async function sendOTP(phone) {
  const result = await new Client({
    path: "/auth",
    payload: {
      phone,
    },
  }).post();

  if (result?.code !== 200) {
    throw new Error(
      result?.message ||
        "Uh oh! Something went wrong setting up your Kutuki experience. Pleae try again"
    );
  }

  return result;
}

async function resendOTP(phone) {
  const result = await new Client({
    path: "/auth/resend",
    payload: {
      phone,
    },
  }).post();

  if (result?.code !== 200) {
    throw new Error(
      result?.message ||
        "Uh oh! Something went wrong setting up your Kutuki experience. Pleae try again"
    );
  }

  return result;
}

async function verifyOTP(phone, OTP) {
  const result = await new Client({
    path: "/auth/v2/otp/verify",
    payload: {
      phone,
      OTP,
    },
  }).post();

  if (result?.code !== 200) {
    throw new Error(
      result?.message ||
        "Uh oh! Something went wrong setting up your Kutuki experience. Pleae try again"
    );
  }

  return result;
}

async function checkIsStaticOTP(phone) {
  const result = await new Client({
    path: `/auth/static/OTP/${phone}`,
  }).get();

  if (result?.code !== 200) {
    throw new Error(
      result?.message ||
        "Uh oh! Something went wrong setting up your Kutuki experience. Pleae try again"
    );
  }

  return result?.isRegistered;
}

export {
  signIn,
  signOut,
  createSession,
  restoreSession,
  setTemporaryToken,
  sendOTP,
  resendOTP,
  verifyOTP,
  checkIsStaticOTP
};
