import {Client} from "./_clientService";
import {firestore, snapshotToArray} from "./firebaseService"

async function getThemeById(id) {
  const result = await new Client({
    path: `/theme/${id}`
  }).get();

  if (result.code !== 200) {}

  return result?.data || {};
}

async function getThemes({ page, ...conditions }) {
  let finalPath = `/theme?pageNo=${page}&includeTotal=true`;

  if (conditions.courseId && conditions.courseId !== -99)
    finalPath += `&courseId=${conditions.courseId}`;
  else
    return {
      data: [],
      totalEntries: 30,
      pageSize: 30,
    };
  
  if (conditions.subCourseId && conditions.subCourseId !== -99)
    finalPath += `&subCourseId=${conditions.subCourseId}`;

  const result = await new Client({
    path: finalPath,
  }).get();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  return {
    data: result?.data.themes || [],
    totalEntries: result.data.total,
    pageSize: result.data.pageSize,
  };
}

async function getContentByTheme(themeId, { page, courseId, subCourseId }) {
  let finalPath = `/content?pageNo=${page}&themeId=${themeId}&includeTotal=true`;

  if (courseId && courseId !== -99)
    finalPath += `&courseId=${courseId}`;
  
  if (subCourseId && subCourseId !== -99)
    finalPath += `&subCourseId=${subCourseId}`;

  const results = await Promise.all([
    getThemeById(themeId),
    new Client({
      path: finalPath,
    }).get()
  ]);

  if (results.error) {
    throw new Error(results[1].message);
  }

  const data = results[1]?.data?.contents.map(curr => {
    const {streams, title, type, ...rest} = curr;
    return {
      file: streams ? streams[0].url : "",
      name: title,
      type: type.toUpperCase(),
      ...rest,
    }
  });

  return {
    data: data || [],
    totalEntries: results[1].data.total,
    pageSize: results[1].data.pageSize,
    pageData: {theme: results[0]}
  };
}

async function getContentById(id) {
  const result = await new Client({
    path: `/content/${id}`,
  }).get();

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  return result?.content || {};
}

async function getThemeByIdFirestore(id) {
  const snapshot = await firestore('kutuki-content-store')
    .collection('theme')
    .doc(id)
    .get();
  
  const theme = snapshot.exists && snapshot.data()
    ? snapshot.data()
    : null;
  
  return { data: theme };
}

async function getThemesFirestore({}) {
  
  const snapshot = await firestore('kutuki-content-store')
    .collection('theme')
    .get();
  
  const themes = snapshotToArray(snapshot);
  
  const filteredThemes = themes.map(theme => ({
      id: theme.id,
      thumbnailUrl: theme?.thumbnail?.image?.url ?? "",
      metadata: theme?.metadata ?? {},
      label: theme?.title ?? "",
      displayPosition: theme?.displayPosition,
    }))
    .filter(theme => !["New"].includes(theme.label))
    .sort((a, b) => {
      if (a.displayPosition > b.displayPosition)
        return 1;
      else if (a.displayPosition < b.displayPosition)
        return -1;
      else
        return 0;
    })
  
  return {
    data: filteredThemes || [],
    totalEntries: themes.length,
    pageSize: 40,
  };
}

async function getContentByThemeFirestore(themeId) {
  const theme = await getThemeById(themeId);
  
  const snapshot = await firestore('kutuki-content-store')
    .collection(`/theme/${themeId}/content`)
    .get();
  
  const content = snapshotToArray(snapshot);
  
  const data = content.map(curr => {
    const {streams, title, type, thumbnail, ...rest} = curr;
    
    return {
      ...rest,
      file: streams ? streams[0].url : "",
      name: title,
      type: type.toUpperCase(),
      thumbnailUrl: thumbnail?.image?.url ?? "",
    }
  }).sort((a, b) => {
    if (a.displayPosition > b.displayPosition)
      return 1;
    else if (a.displayPosition < b.displayPosition)
      return -1;
    else
      return 0;
  })
  
  return {
    data: data || [],
    totalEntries: data.length,
    pageSize: data.length,
    pageData: { theme },
  };
}

async function getContentByIdFirestore(id) {
  const snapshot = await firestore()
    .collection('content')
    .doc(id)
    .get();
  
  return snapshot.exists && snapshot.data() ? snapshot.data() : null;
}

export {
  getThemeById,
  getThemes,
  getContentByTheme,
  getContentById,
  getThemeByIdFirestore,
  getThemesFirestore,
  getContentByIdFirestore,
  getContentByThemeFirestore,
};
