import React from "react";
import queryString from 'query-string'
import {useParams, Link as RouterLink, useLocation} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import useList from "hooks/useList";
import ContentShell from "components/ContentShell";
import ContentPreview from "components/ContentPreviewV2";
import Gallery from "components/Gallery";
import {getContentByThemeFirestore} from "services/contentService";

const screenName = "learning-content";

function ThemeContent() {
  const { id } = useParams();
  
  const {search} = useLocation();

  const [openContentViewer, setOpenContentViewer] = React.useState(false);

  const [selected, setSelected] = React.useState(null);

  const values = queryString.parse(search);

  const {
    loading,
    list,
    total,
    pageData,
    page,
    pageSize,
    changePage,
  } = useList({
     getList: async () => await getContentByThemeFirestore(id),
     screenName
});

  const handleClick = (contentId) => {
    const content = list.filter(curr => curr.id === contentId)?.[0] || {};
    setSelected(content);
    setOpenContentViewer(true);
  };

  const handleClose = () => {
    setOpenContentViewer(false);
    setSelected(null);
  };


  const themeName = pageData?.theme?.name || "";

  return (
    <ContentShell
      loading={loading}
      title={
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/rhymes-and-stories" component={RouterLink} underline="hover">
            <Typography variant="h6" color="primary">Rhymes & Stories</Typography>
          </Link>
          <Typography color="textPrimary">{themeName}</Typography>
        </Breadcrumbs>
      }
    >
      <Gallery
        list={list}
        currentPage={page}
        pageSize={pageSize}
        totalEntries={total}
        onChangePage={changePage}
        onThumbnailClick={handleClick}
      />
      <ContentPreview
        open={openContentViewer}
        initialFile={selected}
        files={list}
        title={`Rhymes & Stories / ${themeName}`}
        onClose={handleClose}
      />
    </ContentShell>
  );
}

export default ThemeContent;
